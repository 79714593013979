import {MutableRefObject, useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Tree} from "../utils/model";

export function useClickAwayListener(ref: MutableRefObject<any>, clickAwayCallback: () => any) {
    useEffect(() => {
        if (typeof document === "undefined") {
            return;
        }
        function handleClickAway(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                clickAwayCallback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickAway);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickAway);
        };
    }, [ref]);
}

export const useCategoryTree = () => {
    const { categories } = useStaticQuery(
      graphql`
        query CategoryTreeQuery {
            categories: allAirtable(filter: {table: {eq: "Category"}}) {
                nodes {
                    id
                    data {
                        Order
                        Name
                        CategoryPicto {
                            localFiles {
                                publicURL
                            }
                        }
                        HasDedicatedPage
                        Organizations {
                            id
                        }
                    }
                }
            }
            
        }
      `
    )
    return new Tree('/categories', categories.nodes)
}
  