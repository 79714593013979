import React, {useEffect} from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {allCategoriesType} from "../utils/model";
import queryString from 'query-string'
import {sortIds} from "../utils/commonUtils";
import {useCategoryTree} from "../utils/custom-hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronCircleUp} from "@fortawesome/free-solid-svg-icons"
const slug = require('slug')

type CategoryTemplateProps = {
    data:  { 
        categories: allCategoriesType,
        category,
        organizations: any
    }
    pageContext: { 
        categoryId,
        airtableId: string
    }
}

function CategoryTemplate(props: CategoryTemplateProps) {

    let tags = props.data.organizations.nodes.map(org => org.data.Tags)
        .flat(1)
        .map(o => o?.data.Tag.toLowerCase())
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .sort();

    const totalOrgCount = props.data.organizations.nodes.length;
    let categories = useCategoryTree();

    const category = categories.find(props.pageContext.categoryId)

    category.desc = props.data?.category.data.Description?.childMarkdownRemark.html;
    category.SEOTitle = props.data?.category.data.SEOTitle;
    category.SEODesc = props.data?.category.data.SEODesc;
    category.keyFigures = props.data?.category.data.Highlights?.childMarkdownRemark.html;
    category.contributionType = props.data?.category.data.contributionType;

    useEffect(() => {
        document.body.className = "page-category"
    }, []);

   
    return (
        <Layout contentClassName="bg-gray-100">
           <SEO title={`${category.SEOTitle}` || `${category.originalName} : les organisations sur CartoJob`} 
            description={`${category.SEODesc}`} 
            gtmPageType="secteur"
            gtmPageName={`secteur-${category.name}`}
            />
            <div className="overflow-x-hidden">
                <div className="px-2 container md:mx-auto max-w-3xl">
                    <p className="font-sans text-xs text-gray-700 mt-8 uppercase tracking-widest lg:max-w-lg ">Secteur</p>
                    <h1 className="text-3xl xl:text-5xl leading-none text-primary font-black mb-3">{category.originalName}</h1> 
                    {(
                    <div className="flex flex-wrap justify-start text-2xs lg:text-xs pr-1 mb-4">
                    <div className="flex items-center"><div className="mr-3 font-sans ">dans</div> 
                        { 
                            category.ancestors().reverse().map(ancestor => (
                                <div className="breadcrumb-item flex items-center "> 
                                    <Link key={ancestor.id} to={ancestor.slug} className="text-primary text-xs font-sans bg-white flex-initial rounded border border-gray-200 text-primary px-2 py-1 no-underline transition duration-300 ease hover:text-white hover:bg-primary">
                                        {ancestor.originalName} 
                                    </Link>
                                    <span className="sep leading-3 mx-1">❭</span>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    )}  
                    <div className="text-primary font-bold text-sm font-sans">
                        {totalOrgCount} organisations <br className="md:hidden" /> dans ce secteur &nbsp;
                        <Link to={"/search/?"+queryString.stringify(category.filters().filters)}
                            className="custom-underline ">
                            Voir les organisations
                        </Link>
                    </div>                 
                </div>
              
                <div className="mx-2 pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pb-16 ">
                    <div className="hidden lg:block float-right ml-8">
                    {(category.children.length > 0) && (
                        <div className="bg-primary text-white relative pt-16 pb-8 px-8 md:pr-0 mt-16 max-w-md hidden lg:block">
                            <img className="absolute bg-white rounded-md shadow-xl p-6" style={{width: "130px", height: "130px", top:"-70px", left:"35px"}} src={category.pictoURL} />
                            <div className="text-lg font-sans font-bold " style={{maxWidth: "280px"}} >
                                <h2 className="text-lg  mt-4 font-bold text-secondary">Les sous-secteurs dans “<span className="capitalize-first-letter">{category.name}</span>”</h2>

                                <ul className="flex justify-left items-center flex-wrap 2xl:mx-10">
                                    {category
                                    .children
                                    .sort(sortIds("id"))
                                    .map(cat => (

                                    <li key={cat.id} className="font-sans">
                                        <Link to={cat.hasDedicatedPage ? cat.slug : "/search/?"+queryString.stringify(cat.filters().filters) }>
                                            <div className="no-underline inline-block leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-3  capitalize-first-letter transition duration-300 ease hover:border-white flex items-center">
                                                <span className="text-sm">{cat.name}</span>
                                                <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
                                                <span className="text-sm">{cat.totalOrgCount}</span>
                                            </div>
                                        </Link>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="absolute h-full bg-primary left-auto  z-0 top-0 right-0 transform translate-x-full" style={{width:"calc(100%)"}}></div>
                        </div>
                    )}
                    {category.keyFigures && (
                        <div className="bg-shift text-white relative p-24 pr-8 md:pr-0 mt-4 mb-8 max-w-md hidden lg:block">
                            <div className="text-lg font-sans font-bold " dangerouslySetInnerHTML={{__html: category.keyFigures }} style={{maxWidth: "280px"}} />
                            <div className="absolute h-full bg-shift left-auto  z-0 top-0 right-0 transform translate-x-full" style={{width:"calc(100%)"}}></div>
                        </div>
                    )}
                    </div>
                    <div className="px-2 container md:mx-auto max-w-3xl">

                        {category.keyFigures && (
                        <div className="bg-primary text-white relative p-10 mt-20 mb-8 lg:hidden">
                            <img className="absolute bg-white rounded-md shadow-xl p-3" style={{maxWidth:"100px", maxHeight:'100px', top:"-4em", left:"1em"}} src={category.pictoURL} />
                            <div className="text-lg font-sans font-bold "  dangerouslySetInnerHTML={{__html: category.keyFigures }} />
                        </div>
                        )}

                        <div
                            className="markdown"
                            dangerouslySetInnerHTML={{
                                __html: category.desc
                            }}
                        />
                        
                    </div>

                    <div className="px-2 container md:mx-auto max-w-3xl text-sm py-3 mt-6 font-black font-sans text-primary border-t border-gray-300">
                        Organisations associées au secteur “<span className="capitalize-first-letter">{category.name}</span>”
                        <span className="float-right">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faChevronCircleUp}
                                className="self-center"
                            />
                        </span>
                    </div>
                    <div className="px-2 container md:mx-auto max-w-3xl text-sm font-sans " style={{columnCount: 6 }}>
                        
                        {props.data.organizations.nodes
                        .sort((a, b) => {
                            if (a.data.Name < b.data.Name) {
                              return -1
                            }
                            if (a.data.Name > b.data.Name) {
                              return 1
                            }
                    
                            return 0
                          })
                        .map(org => (

                            <Link to={`/organizations/${slug(org.data.Name)}`}>{org.data.Name}<br/></Link>

                        ))}

                    </div>

                    <div className="px-2 container md:mx-auto max-w-3xl text-sm py-3 mt-6 font-black font-sans text-primary border-t border-gray-300">
                        Mots clés associés au secteur “<span className="capitalize-first-letter">{category.name}</span>”
                        <span className="float-right">
                            <FontAwesomeIcon
                                fixedWidth
                                icon={faChevronCircleUp}
                                className="self-center"
                            />
                        </span>
                    </div>
                    <div className="px-2 container md:mx-auto max-w-3xl text-sm font-sans " style={{columnCount: 6 }}>
                        
                        {tags.map(tag => (
                            <span className="capitalize-first-letter">{tag}<br/></span>
                        ))}

                    </div>
                </div>
            </div>

            <div className="pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pb-16">
                    <div className="container md:mx-auto max-w-3xl ">
                    <small className="block mt-8 font-sans font-bold font-sm mb-8">
                        Dans l’esprit collaboratif qui a été au cœur de ce projet, vous
                        pouvez suggérer des ajouts ou des modifications. Ces suggestions
                        seront soumises au même processus de validation qu’à la création de
                        la base de données.
                    </small>
                    <Link
                        to="/contribuer"
                        className="inline-flex no-underline font-sans font-bold text-sm bg-transparent rounded border-2 border-secondary text-primary text-center px-6 py-3 transition duration-300 ease transition-colors hover:bg-secondary hover:text-primary "
                    >
                       Suggérer une modification
                    </Link>
                    </div>
                </div>
            {(category.children.length > 0) && (
                <div id="sous-secteurs" className="bg-primary text-white relative p-10 mt-16 mb-16 text-center lg:hidden">

                    <div className="absolute" style={{top:"-40px", left:"50%"}}>
                        <img className="relative bg-white p-4 shadow-xl rounded" style={{left:"-50%", width: "80px", height: "80px"}} src={category.pictoURL} />
                    </div>
                    <h2 className="text-2xl  mt-4 font-bold">Les sous-secteurs dans “<span className="capitalize-first-letter">{category.name}</span>”</h2>

                    <ul className="flex justify-center items-center flex-wrap 2xl:mx-10">
                        {category
                        .children
                        .sort(sortIds("id"))
                        .map(cat => (

                        <li key={cat.id} className="font-sans mx-2">
                            <Link to={cat.hasDedicatedPage ? cat.slug : "/search/?"+queryString.stringify(cat.filters().filters) }>
                                <div className="no-underline inline-block leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-4  capitalize-first-letter transition duration-300 ease hover:border-white flex items-center">
                                    <span className="text-sm">{cat.name}</span>
                                    <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
                                    <span className="text-sm">{cat.totalOrgCount}</span>
                                </div>
                            </Link>
                        </li>
                        ))}
                    </ul>

                    <Link to="/secteurs/" className="no-underline inline-block leading-snug text-secondary font-bold rounded border-2 border-secondary px-4 py-2 mt-10 transition duration-300 ease hover:bg-secondary hover:text-primary ">
                        <span className="font-sans text-sm  capitalize-first-letter">Tous les secteurs</span>
                    </Link>
                </div>
            )}


            <div className="w-full fixed left-0 bottom-0 flex justify-center items-center text-sm  font-bold bg-white py-3 px-6 shadow-xl">
                <div className="container md:mx-auto max-w-3xl flex justify-center items-center">
                <span className="text-right  lg:text-2xl font-sans text-primary pr-5 md:text-center whitespace-no-wrap ">
                    {totalOrgCount} organisations <br className="md:hidden" /> dans ce secteur
                </span>
                <Link to={"/search/?"+queryString.stringify(category.filters().filters)} 
                className="font-sans whitespace-no-wrap w-auto bg-secondary p-2 text-primary text-center rounded px-8 py-4 transition duration-300 ease hover:bg-primary hover:text-secondary ">
                    Voir les organisations
                </Link>
                </div>
            </div>
          
        </Layout>
    )
}

export const query = graphql`
query CategoryPageQuery($airtableId: String, $orderRegexp: String) {
    category: airtable(id: {eq: $airtableId }) {
        id
        data {
            Order
            Name
            SEOTitle
            SEODesc
            Description {
                childMarkdownRemark {
                    html
                }
            }
            Highlights {
                childMarkdownRemark {
                    html
                }
            }
            CategoryPicto {
                localFiles {
                    publicURL
                }
            }
            ContributionType
            OrgCount
            HasDedicatedPage
        }
    }
    organizations: allAirtable(
        filter: { 
            table: { eq: "Organization" },
            data: {
                Categories: { elemMatch: { data: {Order : { regex: $orderRegexp }}}},
                Status: {eq: "Validé"}
            },
        }) {
        nodes {
            id
            data {
                Name
                Categories {
                    id 
                    data {
                        Order
                    }
                }
                Tags {
                    id
                    data {
                        Tag
                    }
                }
            }
        }
    }
}
`

export default CategoryTemplate
