export function sortBy<T>(by: keyof T, order: "asc" | "desc" = "asc") {
    const greaterThanNumber = order === "asc" ? 1 : -1
    return (a: T, b: T) => a[by] > b[by] ? greaterThanNumber : -greaterThanNumber
}

export function sortIds<T>(by: keyof T) {
    return function (a: T, b: T) {
        const listA = String(a[by]).split('.').map((str) => parseInt(str));
        const listB = String(b[by]).split('.').map((str) => parseInt(str));
        for (let i = 0; i < Math.max(listA.length, listB.length); i++) {
            const currentA = listA[i] || 0;
            const currentB = listB[i] || 0;
            if (currentA > currentB) return 1;
            if (currentA < currentB) return -1;
        }
        return 0;
    };
}

export function countByReducer<T>(by: keyof T) {
    return (previousValue: Record<string, number>, currentValue: T) => {
        const currentValueCountedByValue = currentValue[by] as any as string;
        return ({
            ...previousValue,
            [currentValueCountedByValue]: (previousValue[currentValueCountedByValue] ?? 0) + 1
        });
    }
}

export function uniqueByValueReducer<T>(accumulatedValues: T[], currentValue: T) {
    if (accumulatedValues.includes(currentValue)) {
        return accumulatedValues
    } else return [...accumulatedValues, currentValue]
}

